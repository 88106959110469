<template>
  <v-preloader v-if="startApp"></v-preloader>
  <v-dashboard v-else>
    <v-preloader v-if="startRequest"></v-preloader>
    <router-view v-else></router-view>
  </v-dashboard>
</template>

<script>
import {mapState} from 'vuex'

import {isLoggedIn} from '@/function/auth.js'
import constants from '@/const/'

export default {
  data() {
    return {
      startApp: false,
      startRequest: false,

      /* Флаг загрузки данных для интеркома */
      isLoadIntercom: false
    }
  },

  computed: {
    ...mapState({
      /* Конфигурация приложения */
      config: state => state.config,

      /* Профиль пользователя */
      profile: state => state.profile,

      /* Список проектов */
      projects: state => state.options.projects,

      /* ID активного проекта */
      active_project: state => state.active_project,

      /* Данные по активному проекту */
      active_project_data: state => state.active_project_data,
    }),

    /* Наличие профиля */
    profileExists() {
      return !!(this.profile && this.profile.id)
    },

    /* Флаг отсутствия проектов */
    lackOfProjects () {
      return this.needingProjects && (!this.projects.list || !this.projects.list.length)
    },

    /* Данные о текущем роуте */
    needingProjects () {
      try {
        return this.$route.matched[this.$route.matched.length - 1].path.indexOf('activeProject') !== -1
      } catch (_) {
        return false
      }
    },

    /* ID активного проекта в URL */
    activeProjectUrl () {
      let id = +this.$route.params.activeProject
      return id ? +id : undefined
    },
  },

  methods: {
    /* Запроc данных */
    async getData() {
      if (!this.startRequest && !this.profileExists && isLoggedIn()) {
        this.startApp = true;

        /* Запрос профиля */
        try {
          await this.$store.dispatch('getProfile');
        } catch (error) {
          this.$notify({type: 'error', title: error});
        }

        /* Запрос списка меню */
        try {
          await this.$store.dispatch('getAsideList');
        } catch (error) {
          this.$notify({type: 'error', title: error});
        }

        /* Запрос списка проектов */
        if (this.lackOfProjects) {
          try {
            await this.$store.dispatch('getProjects')
          } catch (error) {
            this.$notify({type: 'error', title: error})
          }
        }

        /* Запрос данных по проекту */
        try {
          await this.getProjectData()
        } catch (error) {
          this.$notify({type: 'error', title: error})
        }

        await this.getIntercom()

        this.startApp = false;
      }
    },

    async getProjectData (id) {
      return new Promise(async (resolve, reject) => {
        this.startRequest = true

        try {
          if (!id) {
            id = this.activeProjectUrl ||
                this.active_project ||
                +localStorage.getItem(constants.LOCAL_STORAGE_DATA.ACTIVE_PROJECT)
          }

          if (!!id && id !== 0) {
            /* Сохранение ID проекта */
            await this.$store.commit('initActiveProject', id)

            /* Запрос данных по конкретному проекту */
            await this.$store.dispatch('getProject', {
              id: id
            })
          }

          resolve(true)
        } catch (error) {
          reject(error)
        }

        this.startRequest = false
      })
    },

    /* Запрос данных и обновление виджета Intercom */
    async getIntercom () {
      if (!this.isLoadIntercom && this.$intercom && this.profile && this.profile.demo_mode !== true) {
        this.$store.dispatch('getIntercom').then(userData => {
          this.$intercom.hide()
          this.$intercom.update(userData)
        })

        this.$set(this, 'isLoadIntercom', true)
      }
    },
  },

  watch: {
    async '$route' (to, from) {
      /* Запрос данных по проекту */
      if (to.params.activeProject && to.params.activeProject !== from.params.activeProject) {
        try {
          await this.getProjectData()
        } catch (error) {
          this.$notify({type: 'error', title: error})
        }
      }

      if (!isLoggedIn()) {
        this.$store.commit('getProfile', {});
        this.$set(this, 'isLoadIntercom', false)
      }
    }
  },

  async created() {
    /* Загрузка данных */
    if (isLoggedIn() && !this.profileExists) {
      await this.getData();
    }
  }
};

</script>

<style lang="scss" scoped>
</style>
